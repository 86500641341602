import { Result, Spin } from "antd";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// LAZY LOAD COMPONENTS
const Dashboard = lazy(() => import("../pages/Dashboard"));

const Admins = lazy(() => import("../pages/Admins"));
const EditAdmin = lazy(() => import("../pages/Admins/EditAdmin"));

const Categories = lazy(() => import("../pages/Categories"));

const Brands = lazy(() => import("../pages/Brands"));

const Options = lazy(() => import("../pages/Options"));

const Products = lazy(() => import("../pages/Products"));
const EditProduct = lazy(() => import("../pages/Products/EditProduct"));

const Terms = lazy(() => import("../pages/Terms"));
const EditTerm = lazy(() => import("../pages/Terms/EditTerm"));

const Users = lazy(() => import("../pages/Users"));
const EditUser = lazy(() => import("../pages/Users/EditUser"));

const Orders = lazy(() => import("../pages/Orders"));
const OrderForm = lazy(() => import("../pages/Orders/OrderForm"));

const Credits = lazy(() => import("../pages/Credits"));
//const CreditForm = lazy(() => import(""));

const Forum = lazy(() => import("../pages/Forum"));
const ForumForm = lazy(() => import("../pages/Forum/EditPost"));

const Faqs = lazy(() => import("../pages/Faqs"));

const Baners = lazy(() => import("../pages/Baners"));

const Messages = lazy(() => import("../pages/Messages"));
const EditMessage = lazy(() => import("../pages/Messages/EditMessage"));

// const Agents = lazy(() => import('../pages/Agents'));
// const EditAgent = lazy(() => import('../pages/Agents/EditAgent'));

// const Agencies = lazy(() => import('../pages/Agencies'));
// const EditAgency = lazy(() => import('../pages/Agencies/EditAgency'));

// // const Donations = lazy(() => import('../pages/Donations'));

// const Properties = lazy(() => import('../pages/Properties'));
// const EditProperty = lazy(() => import('../pages/Properties/EditProperty'));

// const Requests = lazy(() => import('../pages/Requests'));
// const EditRequest = lazy(() => import('../pages/Requests/EditRequest'));

// const Contacts = lazy(() => import('../pages/Contacts'));
// const EditContact = lazy(() => import('../pages/Contacts/EditContact'));
// const Faq = lazy(() => import('../pages/Faq'));
// const EditFaq = lazy(() => import('../pages/Faq/EditFaq'));

// const Partners = lazy(() => import('../pages/Partners'));
// const EditPartner = lazy(() => import('../pages/Partners/EditPartner'));

const AppRouter = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/create" element={<EditProduct />} />
        <Route
          path="/products/clone/:productId"
          element={<EditProduct clone />}
        />
        <Route path="/products/:productId" element={<EditProduct />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/options" element={<Options />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/admins/:adminId" element={<EditAdmin />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms/create" element={<EditTerm />} />
        <Route path="/terms/:termId" element={<EditTerm />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:userId" element={<EditUser />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:orderId" element={<OrderForm />} />
        <Route path="/credits" element={<Credits />} />
        {/*<Route path="/credits/:creditId" element={<Credits />} />*/}
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/forums" element={<Forum />} />
        <Route path="/forums/:postId" element={<ForumForm />} />
        <Route path="/baners" element={<Baners />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/messages/create" element={<EditMessage />} />
        <Route path="/messages/:messageId" element={<EditMessage />} />
        <Route element={<Result status="404" title="404" />} />;
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
