import { Menu } from "antd";
import React, { ReactNode, useContext, useState } from "react";
import {
  UserOutlined,
  TagsOutlined,
  BankOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  FormOutlined,
  OrderedListOutlined,
  ReconciliationOutlined,
  FileProtectOutlined,
  ShoppingOutlined,
  BranchesOutlined,
  TagOutlined,
  DeploymentUnitOutlined,
  AppstoreAddOutlined,
  ShoppingCartOutlined,
  SafetyCertificateOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CommonStore from "../../store/CommonStore";
import { AdminRoles } from "../../types";
import { Link, useLocation } from "react-router-dom";

type MenuType = {
  key: string;
  label: string | ReactNode;
  icon: ReactNode;
  items?: { key: string; label: string | ReactNode; icon: ReactNode }[];
};

const { SubMenu, Item } = Menu;

const NavigationBar: React.FC<{ theme: "dark" | "light" }> = ({ theme }) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const [moderationQnt, setModerationQnt] = useState(0);

  //
  const menu: { [key: number]: MenuType[] } = {
    [AdminRoles.SUPER_ADMIN]: [
      {
        key: "",
        label: t("Catalog"),
        icon: <ShoppingOutlined />,
        items: [
          {
            key: "products",
            label: t("Products"),
            icon: <ShoppingOutlined />,
          },
          {
            key: "categories",
            label: t("Categories"),
            icon: <BranchesOutlined />,
          },
          {
            key: "brands",
            label: t("Brands"),
            icon: <TagOutlined />,
          },
          {
            key: "options",
            label: t("Options"),
            icon: <DeploymentUnitOutlined />,
          },
        ],
      },
      {
        key: "orders",
        label: t("Orders"),
        icon: <ShoppingCartOutlined />,
      },
      {
        key: "credits",
        label: t("Credits"),
        icon: <DollarCircleOutlined />,
      },
      {
        key: "users",
        label: t("Users"),
        icon: <TeamOutlined />,
      },
      {
        key: "terms",
        label: t("Terms and Privacy"),
        icon: <SafetyCertificateOutlined />,
      },
      {
        key: "forums",
        label: t("Forum"),
        icon: <FormOutlined />,
      },
      {
        key: "messages",
        label: t("Messages"),
        icon: <MessageOutlined />,
      },
      {
        key: "baners",
        label: t("Baners"),
        icon: <PictureOutlined />,
      },
      {
        key: "faqs",
        label: t("FAQ"),
        icon: <QuestionCircleOutlined />,
      },
      {
        key: "admins",
        label: t("Admins"),
        icon: <UserOutlined />,
      },
    ],

    [AdminRoles.ADMIN]: [
      {
        key: "",
        label: t("Catalog"),
        icon: <ShoppingOutlined />,
        items: [
          {
            key: "products",
            label: t("Products"),
            icon: <ShoppingOutlined />,
          },
          {
            key: "categories",
            label: t("Categories"),
            icon: <BranchesOutlined />,
          },
          {
            key: "brands",
            label: t("Brands"),
            icon: <TagOutlined />,
          },
          {
            key: "options",
            label: t("Options"),
            icon: <DeploymentUnitOutlined />,
          },
        ],
      },
      {
        key: "orders",
        label: t("Orders"),
        icon: <ShoppingCartOutlined />,
      },
      {
        key: "credits",
        label: t("Credits"),
        icon: <DollarCircleOutlined />,
      },
      {
        key: "users",
        label: t("Users"),
        icon: <TeamOutlined />,
      },
      {
        key: "forums",
        label: t("Forum"),
        icon: <FormOutlined />,
      },
      {
        key: "messages",
        label: t("Messages"),
        icon: <MessageOutlined />,
      },
      {
        key: "baners",
        label: t("Baners"),
        icon: <PictureOutlined />,
      },
      {
        key: "faqs",
        label: t("FAQ"),
        icon: <QuestionCircleOutlined />,
      },
    ],

    [AdminRoles.MANAGER]: [
      {
        key: "",
        label: t("Catalog"),
        icon: <ShoppingOutlined />,
        items: [
          {
            key: "products",
            label: t("Products"),
            icon: <ShoppingOutlined />,
          },
          {
            key: "categories",
            label: t("Categories"),
            icon: <BranchesOutlined />,
          },
          {
            key: "brands",
            label: t("Brands"),
            icon: <TagOutlined />,
          },
          {
            key: "options",
            label: t("Options"),
            icon: <DeploymentUnitOutlined />,
          },
        ],
      },
      {
        key: "orders",
        label: t("Orders"),
        icon: <ShoppingCartOutlined />,
      },
      {
        key: "credits",
        label: t("Credits"),
        icon: <DollarCircleOutlined />,
      },
      {
        key: "users",
        label: t("Users"),
        icon: <TeamOutlined />,
      },
      {
        key: "forums",
        label: t("Forum"),
        icon: <FormOutlined />,
      },
    ],
  };

  const activeRoute = location.pathname.split("/")[1] || "";

  //
  const openedKey = () => {
    let ret = "";
    if (storeCommon.user) {
      menu[storeCommon.user.role].forEach((item) => {
        const search = item.items?.find((sub) => {
          return sub.key.split("/")[0] === activeRoute;
        });
        if (search) {
          ret = item.key;
          return;
        }
      });
    }
    return ret;
  };

  //
  // useEffect(() => {
  //   (async () => {
  //     if (storeCommon.user?.role !== AdminRoles.SUPERADMIN) return;

  //     const respSynagogues = await api(
  //       "synagogues?per-page=100&page=1&sort=id&filter[and][0][or][][synagogue.status]=10"
  //     );
  //     setModerationQnt(respSynagogues.total);
  //   })();
  // }, [location.pathname]);

  return (
    <Menu
      // onClick={this.handleClick}
      id={`menu-${storeCommon.user?.id}`}
      style={{ width: 256 }}
      mode="inline"
      theme={theme}
      defaultOpenKeys={[openedKey()]}
      defaultSelectedKeys={[activeRoute]}
      key={`menu-${activeRoute}`}
    >
      {storeCommon.user &&
        menu[storeCommon.user.role].map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!item.items && (
                <Item icon={item.icon} key={item.key}>
                  <Link to={`/${item.key}`}>{item.label}</Link>
                </Item>
              )}
              {item.items && (
                <SubMenu key={item.key} icon={item.icon} title={item.label}>
                  {item.items.map((sub) => {
                    return (
                      <Item icon={sub.icon} key={sub.key}>
                        <Link to={`/${sub.key}`}>{sub.label}</Link>
                      </Item>
                    );
                  })}
                </SubMenu>
              )}
            </React.Fragment>
          );
        })}
    </Menu>
  );
};

export default NavigationBar;
