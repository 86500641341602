import { useContext, useEffect, useState } from "react";
import CommonStore from "./store/CommonStore";
import "antd/dist/antd.css";
import "./App.css";
import { Observer, Provider } from "mobx-react";
import { ConfigProvider, Spin } from "antd";
import { BrowserRouter } from "react-router-dom";
import { Admin } from "./types";
import api from "./api/api";
import MainLayout from "./components/MainLayout";
import GuestRouter from "./router/GuestRouter";
import en from "antd/lib/locale/en_US";

const App = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const [loaded, setLoaded] = useState(false);

  //
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (!localStorage.getItem("token")) {
        window.location.href = "/login";
      }
    });

    (async () => {
      const resp: Admin = await api("admins/me");
      if (resp?.id) {
        storeCommon.setUser(resp);
      } else {
        // const route = window.location.pathname.split("/")[1];
        // if (
        //   !["forgot-password", "sign-up", "invite", "reset-password"].includes(
        //     route
        //   )
        // )
        // history.replace("/login");
      }
      setLoaded(true);
    })();
  }, []);

  return (
    <Provider storeCommon={storeCommon}>
      <BrowserRouter>
        <ConfigProvider locale={en} direction="ltr">
          <Observer>
            {() => {
              if (loaded) {
                if (storeCommon.user) {
                  return <MainLayout />;
                  // return <></>;
                }
                return <GuestRouter />;
              }

              return (
                <div
                  style={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin size="large" />
                </div>
              );
            }}
          </Observer>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
